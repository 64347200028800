import { Component, OnInit } from '@angular/core';
import { ISpinnerAnimation } from '../ngx-spinner.types';

@Component({
  selector: 'app-rotating-dots',
  template: `<div class="spinner" [style.height]="config.size" [style.width]="config.size">
  <div class="dot1" [style.background]="config.color"></div>
  <div class="dot2" [style.background]="config.color"></div>
</div>`,
  styles: [`.spinner{position:relative;text-align:center;-webkit-animation:2s linear infinite sk-rotate;animation:2s linear infinite sk-rotate}.dot1,.dot2{width:60%;height:60%;display:inline-block;position:absolute;top:0;border-radius:100%;-webkit-animation:2s ease-in-out infinite sk-bounce;animation:2s ease-in-out infinite sk-bounce}.dot2{top:auto;bottom:0;-webkit-animation-delay:-1s;animation-delay:-1s}@-webkit-keyframes sk-rotate{100%{-webkit-transform:rotate(360deg)}}@keyframes sk-rotate{100%{transform:rotate(360deg);-webkit-transform:rotate(360deg)}}@-webkit-keyframes sk-bounce{0%,100%{-webkit-transform:scale(0)}50%{-webkit-transform:scale(1)}}@keyframes sk-bounce{0%,100%{transform:scale(0);-webkit-transform:scale(0)}50%{transform:scale(1);-webkit-transform:scale(1)}}`]
})
export class RotatingDotsComponent implements ISpinnerAnimation {
	config;

  constructor() { }

  ngOnInit() {
  }

}
