import { Component, OnInit } from '@angular/core';
import { ISpinnerAnimation } from '../ngx-spinner.types';

@Component({
  selector: 'app-horizontal-bounce',
  template: `<div class="spinner">
  <div class="bounce1" [style.height]="config.size" [style.width]="config.size" [style.background]="config.color"></div>
  <div class="bounce2" [style.height]="config.size" [style.width]="config.size" [style.background]="config.color"></div>
  <div class="bounce3" [style.height]="config.size" [style.width]="config.size" [style.background]="config.color"></div>
</div>`,
  styles: [`.spinner{text-align:center}.spinner>div{margin:0 .15rem;border-radius:100%;display:inline-block;-webkit-animation:1.4s ease-in-out infinite both sk-bouncedelay;animation:1.4s ease-in-out infinite both sk-bouncedelay}.spinner .bounce1{-webkit-animation-delay:-.32s;animation-delay:-.32s}.spinner .bounce2{-webkit-animation-delay:-.16s;animation-delay:-.16s}@-webkit-keyframes sk-bouncedelay{0%,100%,80%{-webkit-transform:scale(0)}40%{-webkit-transform:scale(1)}}@keyframes sk-bouncedelay{0%,100%,80%{-webkit-transform:scale(0);transform:scale(0)}40%{-webkit-transform:scale(1);transform:scale(1)}}`]
})
export class HorizontalBounceComponent implements ISpinnerAnimation {
	config;

  constructor() { }

  ngOnInit() {
  }

}
