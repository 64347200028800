import { Component, OnInit } from '@angular/core';
import { ISpinnerAnimation } from '../ngx-spinner.types';

@Component({
  selector: 'app-spin4',
  template: ``,
  styles: [``]
})
export class Spin4Component implements ISpinnerAnimation {
	config;

  constructor() { }

  ngOnInit() {
  }

}
