import { Component, OnInit } from '@angular/core';
import { ISpinnerConfig, ISpinnerAnimation } from '../ngx-spinner.types';

@Component({
  selector: 'app-fb-card',
  template: `<div class="FB-Loading-Card" [style.height]="config.size" [style.width]="config.size">
	<div>
	 <div [style.background]="config.color"></div>
	 <div [style.background]="config.color"></div>
	 <div [style.background]="config.color"></div>
	</div>
	<div [style.background]="config.color"></div>
	<div [style.background]="config.color"></div>
	<div [style.background]="config.color"></div>
 </div>`,
  styles: [`@charset "UTF-8";.FB-Loading-Card{display:block;padding:12px}.FB-Loading-Card>div:nth-child(1){width:100%;height:30px}.FB-Loading-Card>div>div:nth-child(1){position:relative;left:0;height:40px;width:40px;-webkit-animation-name:shine;animation-name:shine;-webkit-animation-duration:2s;animation-duration:2s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}.FB-Loading-Card>div:nth-child(2),.FB-Loading-Card>div:nth-child(3),.FB-Loading-Card>div:nth-child(4),.FB-Loading-Card>div>div:nth-child(2),.FB-Loading-Card>div>div:nth-child(3){position:relative;height:6px;-webkit-animation-name:shine;animation-name:shine;-webkit-animation-duration:2s;animation-duration:2s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}@-webkit-keyframes shine{from{transition:none}to{background-color:#f6f7f8;transition:all .3s ease-out}}@keyframes shine{from{transition:none}to{background-color:#f6f7f8;transition:all .3s ease-out}}.FB-Loading-Card>div>div:nth-child(2){left:50px;top:-33px;width:25%}.FB-Loading-Card>div>div:nth-child(3){left:50px;top:-20px;width:15%}.FB-Loading-Card>div:nth-child(2){left:0;top:30px;width:80%}.FB-Loading-Card>div:nth-child(3){left:0;top:45px;width:90%}.FB-Loading-Card>div:nth-child(4){left:0;top:60px;width:50%}`]
})
export class FbCardComponent implements ISpinnerAnimation {

	config: ISpinnerConfig;
  constructor() { }

  ngOnInit() {
  }

}
