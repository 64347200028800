import { Component, OnInit } from '@angular/core';
import { ISpinnerAnimation, ISpinnerConfig } from '../ngx-spinner.types';

@Component({
  selector: 'app-double-bounce',
  template: `<div class="spinner" [style.height]="config.size" [style.width]="config.size">
  <div class="double-bounce1" [style.background]="config.color"></div>
  <div class="double-bounce2" [style.background]="config.secondaryColor"></div>
</div>`,
  styles: [`.spinner{position:relative}.double-bounce1,.double-bounce2{width:100%;height:100%;border-radius:50%;opacity:.6;position:absolute;top:0;left:0;-webkit-animation:2s ease-in-out infinite sk-bounce;animation:2s ease-in-out infinite sk-bounce}.double-bounce2{-webkit-animation-delay:-1s;animation-delay:-1s}@-webkit-keyframes sk-bounce{0%,100%{-webkit-transform:scale(0)}50%{-webkit-transform:scale(1)}}@keyframes sk-bounce{0%,100%{transform:scale(0);-webkit-transform:scale(0)}50%{transform:scale(1);-webkit-transform:scale(1)}}`]
})
export class DoubleBounceComponent implements ISpinnerAnimation {

	config: ISpinnerConfig;
  constructor() { }

  ngOnInit() {
  }

}
