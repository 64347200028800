import { Component, OnInit } from '@angular/core';
import { ISpinnerAnimation } from '../ngx-spinner.types';

@Component({
  selector: 'app-spin3',
  template: `<div [style.height]="config.size" [style.width]="config.size" class="lds-ring">
	<div [style.height]="config.size" [style.width]="config.size" [style.borderTopColor]="config.color"></div>
	<div [style.height]="config.size" [style.width]="config.size" [style.borderTopColor]="config.color"></div>
	<div [style.height]="config.size" [style.width]="config.size" [style.borderTopColor]="config.color"></div>
	<div [style.height]="config.size" [style.width]="config.size" [style.borderTopColor]="config.color"></div>
</div>`,
  styles: [`.lds-ring div{box-sizing:border-box;display:block;position:absolute;border:.3rem solid transparent;border-radius:50%;-webkit-animation:1.2s cubic-bezier(.5,0,.5,1) infinite lds-ring;animation:1.2s cubic-bezier(.5,0,.5,1) infinite lds-ring}.lds-ring div:nth-child(1){-webkit-animation-delay:-.45s;animation-delay:-.45s}.lds-ring div:nth-child(2){-webkit-animation-delay:-.3s;animation-delay:-.3s}.lds-ring div:nth-child(3){-webkit-animation-delay:-.15s;animation-delay:-.15s}@-webkit-keyframes lds-ring{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes lds-ring{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}`]
})
export class Spin3Component implements ISpinnerAnimation {
	config;

  constructor() { }

  ngOnInit() {
  }

}
