import { Component, OnInit } from '@angular/core';
import { ISpinnerAnimation, ISpinnerConfig } from '../ngx-spinner.types';

@Component({
  selector: 'spinner-blink',
  template: `<div class="sk-scaleout" [style.height]="config.size" [style.width]="config.size" [style.background]="config.color">
</div>`,
  styles: [`.sk-scaleout{margin:0;border-radius:100%;-webkit-animation:1s ease-in-out infinite sk-scaleout;animation:1s ease-in-out infinite sk-scaleout}@-webkit-keyframes sk-scaleout{0%{-webkit-transform:scale(0)}100%{-webkit-transform:scale(1);opacity:0}}@keyframes sk-scaleout{0%{-webkit-transform:scale(0);transform:scale(0)}100%{-webkit-transform:scale(1);transform:scale(1);opacity:0}}`]
})
export class BlinkComponent implements ISpinnerAnimation {

	config: ISpinnerConfig;
  constructor() { }

  ngOnInit() {
  }

}
