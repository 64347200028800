import { Component, OnInit } from '@angular/core';
import { ISpinnerAnimation } from '../ngx-spinner.types';

@Component({
  selector: 'app-bars',
  template: `<div class="spinner" [style.height]="config.size" >
  <div class="rect1" [style.background]="config.color"></div>
  <div class="rect2" [style.background]="config.color"></div>
  <div class="rect3" [style.background]="config.color"></div>
  <div class="rect4" [style.background]="config.color"></div>
  <div class="rect5" [style.background]="config.color"></div>
</div>`,
  styles: [`.spinner{margin:0}.spinner>div{height:100%;width:.4rem;margin:0 .15rem;display:inline-block;-webkit-animation:1.2s ease-in-out infinite sk-stretchdelay;animation:1.2s ease-in-out infinite sk-stretchdelay}.spinner .rect2{-webkit-animation-delay:-1.1s;animation-delay:-1.1s}.spinner .rect3{-webkit-animation-delay:-1s;animation-delay:-1s}.spinner .rect4{-webkit-animation-delay:-.9s;animation-delay:-.9s}.spinner .rect5{-webkit-animation-delay:-.8s;animation-delay:-.8s}@-webkit-keyframes sk-stretchdelay{0%,100%,40%{-webkit-transform:scaleY(.4)}20%{-webkit-transform:scaleY(1)}}@keyframes sk-stretchdelay{0%,100%,40%{transform:scaleY(.4);-webkit-transform:scaleY(.4)}20%{transform:scaleY(1);-webkit-transform:scaleY(1)}}`]
})
export class BarsComponent implements ISpinnerAnimation {
	config;

  constructor() { }

  ngOnInit() {
  }

}
